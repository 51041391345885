<template>
    <div class="identity">
        <div class="fx" style="border-bottom:1px solid #ccc;padding-bottom:10px;">
            <div>实名认证</div>
            <div class="edits" v-if="!show&&!IdentityForm.reason" @click="show=true,form=JSON.parse(JSON.stringify(IdentityForm))">编辑资料</div>
        </div>
        <div class="content">
            <el-form label-position="left" label-width="90px" :model="form">
                <el-form-item label="证件类型：">
                     <el-select v-model="form.card_type" placeholder="请选择证件类型" v-if="show==true">
                        <el-option  v-for="(item,i) in Status"  :key="i"  :label="item.name"  :value="item.type"></el-option>
                    </el-select>
                    <div v-else>{{IdentityForm.card_type==1?'国内身份证':IdentityForm.card_type==2?'非国内身份证':''}}</div>
                </el-form-item>
                <el-form-item label="姓名：">
                    <el-input v-model="form.realname" v-if="show==true" placeholder="请输入您的姓名"></el-input>
                    <div v-else>{{IdentityForm.realname}}</div>
                </el-form-item>
                <el-form-item label="证件号：">
                    <el-input v-model="form.card_no" v-if="show==true" placeholder="请输入您的证件号"></el-input>
                    <div v-else>{{IdentityForm.card_no}}</div>
                </el-form-item>
                <el-form-item label="证件照：">
                    <div v-if="show==true">
                        <div style="color:#FF0000;">要求：请上传10MB以内的 .jpg/gif/bmp/png/jpeg 图片</div>
                        <div class="flex">
                            <div class="t-center" style="margin-right:40px;">
                                <Upload :accept="'image/*'" @change="getFront" :uploadSize="true" :imgUrl="form.card_front"/>
                                <div>证件正面</div>
                            </div>
                            <div class="t-center">
                                <Upload @change="getBack" :accept="'image/*'" :uploadSize="true" :imgUrl="form.card_back"/>
                                <div>证件反面</div>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <img v-if="IdentityForm.card_front" :src="$commit.getImg(IdentityForm.card_front)" alt="" style="width:260px;height:160px;margin-right:40px;">
                        <img v-if="IdentityForm.card_back" :src="$commit.getImg(IdentityForm.card_back)" alt="" style="width:260px;height:160px;">
                    </div>
                </el-form-item>
                <el-form-item label="驳回理由：" v-if="IdentityForm.reason">
                    <div>{{IdentityForm.reason}}</div>
                </el-form-item>
            </el-form>
            <div class="bottom">
                <div class="btn cursor" v-if="!show&&IdentityForm.reason" @click="show=true,form=JSON.parse(JSON.stringify(IdentityForm))">重新提交</div>
                <div v-if="show">
                    <div class="btn cursor" @click="save">保存</div>
                    <div class="cursor" @click="show=false">取消修改</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import {user_auths_add,user_auths_info,user_auths_edit} from '@/api/user'
export default {
    data() {
        return {
            form:{},
            show:false,
            Status:[
                {name:'国内身份证',type:1},
                {name:'非国内身份证',type:2},
            ],
            IdentityForm:{},
        };
    },
    props:{
        info:{
            default:null,
            type:Object
        }
    },
    created() {
        this.getInfo()
    },
    mounted() {

    },
    methods: {
        //身份详情
        getInfo(){
            user_auths_info().then(res=>{
                if(res.code==200){
                    if(res.data){
                        this.IdentityForm = res.data
                    }else{
                        this.IdentityForm = {}
                    }
                }
            })
        },
        //提交  修改身份认证
        save(){
            let Interface
            if(this.IdentityForm.realname){
                Interface = user_auths_edit
            }else{
                Interface = user_auths_add
            }
            Interface(this.form).then(res=>{
                if(res.code==200){
                    this.$message.success(res.msg)
                    this.$emit('getInfo',res)
                    this.show = false
                    this.getInfo()
                }
            })
        },
        getFront(res){
            this.form.card_front = res
        },
        getBack(res){
            this.form.card_back = res
        }
    }
};
</script>

<style scoped lang="scss">
    .identity{
        .edits{
            color:#CC0000;
            cursor: pointer;
        }
        .content{
            padding: 40px;
            .el-input{
                width: 300px;
            }
            .bottom{
                text-align: center;
                color:#999;
                .btn{
                    margin:10px auto;
                    color: #fff;
                    width: 240px;
                    height: 48px;
                    text-align:center;
                    line-height: 48px;
                    border-radius: 25px;
                    background: #CC0000;
                }
            }
        }
        ::v-deep .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
        }
        ::v-deep .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
        }
        ::v-deep .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 260px;
            height: 160px;
            line-height: 160px;
            text-align: center;
            border-radius: 50%;
        }
        ::v-deep .avatar {
            width: 260px;
            height: 160px;
            display: block;
        }
    }
</style>
